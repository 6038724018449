
.shepherd-element {
    max-width: 280px;
    filter: drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 10px);
}

.shepherd-element * {
    font-family: LL Circular Pro, Arial, Helvetica, sans-serif;
}

.shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before {
    background-color: white;;
}

.shepherd-element, .shepherd-element .shepherd-content {
    border-radius: 12px;
}

.shepherd-element .shepherd-content {
    padding: 12px 24px;
}

@media screen and (min-width: 600px) {
    .shepherd-element .shepherd-content {
        padding: 24px;
    }
}

.shepherd-has-title .shepherd-content > * + * {
    margin-top: 1rem;
}

.shepherd-has-title .shepherd-content .shepherd-header {
    background-color: transparent;
    padding: 0;
    display: block;
}

.shepherd-content .shepherd-text {
    font-size: 14px;
    padding: 0;
}

.shepherd-element .shepherd-header h3 {
    font-size: 20px;
    color: #1A1A1A;
    font-weight: bold;
    display: block;
}

.shepherd-element h1 {
    font-size: 20px;
    color: #1A1A1A;
}

.shepherd-element p {
    font-size: 14px;
}

.shepherd-element .shepherd-button {
    padding: 0 12px;
    height: 36px;
    color: #ffffff
}

.shepherd-element .shepherd-button, .shepherd-element .shepherd-button:hover {
    background-color: #FF6259;
    border-radius: 4px;
}

.shepherd-element .shepherd-button:hover {
    background-color: #ff9586;
}

.shepherd-element .shepherd-button.shepherd-text-button, .shepherd-element .shepherd-button.shepherd-text-button:hover {
    background-color: transparent;
    border-radius: 0;
    color: #1A1A1A;
    text-decoration: underline;
}

.shepherd-element .shepherd-button.shepherd-text-button:hover {
    background-color: transparent;
    
}

.shepherd-element .shepherd-button[disabled], .shepherd-element .shepherd-button:hover[disabled] {
    background-color: #E0E0E0;
}

.shepherd-element.shepherd-wide-tooltip {
    width: 560px;
    max-width: calc(100% - 40px);
    @media screen and (min-width: 600px) {
        max-width: 100%;
    }
}

.shepherd-footer {
    padding: 0;
    position: relative;
}

.shepherd-footer .shepherd-button.shepherd-text-button.shepherd-text-button--left{
    position: absolute;
    left: -12px;
    top: 0;
}

/** Ensure Autocomplete appears on top of shepherd elements **/
.popper-autocomplete, .popper-dropdown {
    z-index: 10000;
}

.shepherd-element.shepherd-left-pinned .shepherd-arrow {
    transform: translate3d(20px, 0px, 0px) !important;
}

.shepherd-element.shepperd-arrow-right-override .shepherd-arrow {
    transform: translate3d(20px 0px, 0px) !important;
}
.shepherd-element.shepperd-arrow-bottom-start-override .shepherd-arrow{
    left: -18px !important;
}
/* shepherd-modal-overlay-container */
