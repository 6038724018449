/* 
    Display utilities
*/

.block { display: block }
.inline-block { display: inline-block }
.flex { display: flex }
.hidden { display: none }

/*
    Text decoration
*/
.no-underline {
    text-decoration: none;
}

/* 
    Cursor utilities
*/

.cursor-pointer { cursor: pointer; }

/*
Stack utilities
*/

.space-x-1 > * + * {
    margin-left: 0.25rem !important;
}

.space-x-2 > * + * {
    margin-left: 0.5rem !important;
}

.space-x-3 > * + * {
    margin-left: 0.75rem !important;
}

.space-x-4 > * + * {
    margin-left: 1rem !important;
}

.space-x-6 > * + * {
    margin-left: 1.5rem !important;
}

.space-y-1 > * + * {
    margin-top: 0.25rem !important;
}

.space-y-2 > * + * {
    margin-top: 0.5rem !important;
}

.space-y-4 > * + * {
    margin-top: 1rem !important;
}

.space-y-6 > * + * {
    margin-top: 1.5rem !important;
}

.space-y-8 > * + * {
    margin-top: 2rem !important;
}

/*
    Font Weight Utilities
*/

.font-medium {
    font-weight: 500;
}
