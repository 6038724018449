.pulsing-button {
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.69),
        rgba(255, 255, 255, 0)
    );
    background-repeat: repeat;
    background-size: 600% 600%;
    
    animation: shimmer 8s infinite;
    animation-timing-function: linear;
}

/*
  Rename pulsing button to shimmer
*/
.animate-shimmer {
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.69),
        rgba(255, 255, 255, 0)
    );
    background-repeat: repeat;
    background-size: 600% 600%;

    animation: shimmer 8s infinite;
    animation-timing-function: linear;
}

@keyframes shimmer {
    0% {
      background-position: 600% 50%;
    }
    50% {
      background-position: 300% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
}


.animate-pulse-salmon {
  box-shadow: 0 0 0 0 rgba(255, 98, 89, 1);
  transform: scale(0.99);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 98, 89, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 98, 89, 0);
  }

  100% {
    transform: scale(0.99);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
