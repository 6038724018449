.swiper-pagination-bullet {
    width: 24px;
    height: 2px;
    background-color: #555;
    border-radius: 0;
    opacity: 1;
}
.swiper-pagination-bullet-active {
    background-color: white;
}
.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 20px;
}
.swiper-button-prev.swiper-button-hidden, .swiper-button-next.swiper-button-hidden {
    opacity: 0;
}
.swiper-button-prev:after {
    content: none;
}
.swiper-button-next:after {
    content: none;
}
.swiper-button-next {
    width: 56px;
    right: 5px;
    z-index: 2;
}
.swiper-button-prev {
    width: 56px;
    left: 5px;
    z-index: 2;
}

.swiper-button-next:focus { 
    outline:0;
}
.swiper-button-prev:focus {
    outline:0;
}

.swiper-slide-full {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* specific use case */
.map-sidebar-swiper .swiper-container .swiper-slide {
    width: auto !important;
}

/* Themes */
/* HomeReport theme */

.home-report .swiper-pagination-bullet {
        width: 10px !important;
        height: 10px !important;
        background: transparent;
        border: 1px solid #1A1A1A;
        border-radius: 50% !important;
        
}

.home-report .swiper-pagination-bullet-active {
    background-color: #000 !important;
}

.home-report-white .swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
    background: transparent;
    border: 1px solid #FFF;
    border-radius: 50% !important;
    
}

.home-report-white .swiper-pagination-bullet-active {
background-color: #FFF !important;
}

@media only screen and (max-width: 600px) {
    .home-report .swiper-pagination-bullets {
        position: relative;
    }

    .home-report-white .swiper-pagination-bullets {
        position: relative;
    }
  }